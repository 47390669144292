import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import DivSeperator from "../components/HomePage/DivSeperator"

import { Container, Row, Col } from "react-bootstrap"

const AboutUs = () => {
  return (
    <Layout>
      <Seo title="AZSzero -- About Us" description="About AZSzero" />

      <Container className="p-5">
        <div className="text-center pb-5 " style={{ position: "relative" }}>
          <StyledH className="display-4">About AZSzero</StyledH>
          <StaticImage
            className="mt-2"
            src="../assets/images/abou-bg.jpg"
            alt="About Amazon FBA"
            placeholder="tracedSVG"
            layout="fullWidth"
            aspectRatio={6 / 1}
            style={{ zIndex: "-1" }}
          ></StaticImage>
        </div>

        <Row className="mx-auto">
          <Col>
            <Row className="mx-auto">
              <Col>
                <StyledH className="text-center pb-5">How It all began</StyledH>
                <Row className="mx-auto" style={{ alignItems: "center" }}>
                  <Col
                    sm={12}
                    md={6}
                    className="pb-5"
                    style={{ textAlign: "right" }}
                  >
                    <StaticImage
                      src="../assets/images/start.jpg"
                      alt="All Amazon Marketplaces In One Dashboard"
                      placeholder="tracedSVG"
                      layout="constrained"
                      aspectRatio={1 / 1}
                      height="400"

                      //                  width="400"
                    ></StaticImage>
                  </Col>
                  <Col sm={12} md={6} className="pb-5">
                    <StyledP>
                      I started Amazon back in 2017, I had few ASINs and few
                      sales a month, it stayed like that for about 6 months, It
                      was easy and relaxed, I just prepare the goods, ship them
                      on time and wait for the sales to come! I had free time,
                      that's when I started to do more ASINs and opened Amazon
                      Canada, Mexico, Japan, and Europe stores...
                    </StyledP>
                  </Col>
                </Row>
              </Col>
            </Row>
            <DivSeperator></DivSeperator>

            <Row className="mx-auto">
              <Col>
                <StyledH className="text-center pb-5">The Problem</StyledH>
                <Row className="mx-auto" style={{ alignItems: "center" }}>
                  <Col sm={12} md={6} className="pb-5">
                    <StyledP>
                      Things got a boost, my sales went up 5 times what they
                      were, I had to do more work, I needed to follow up on
                      orders and shipments, check the slow-moving inventory,
                      ASINs that had no sales, the refunds, the reviews, the
                      feedback from all my stores plus a bunch of other routine
                      things. I download multiple reports like inventory and
                      sales, then on excel sheet I had to calculate when do I
                      need to make my orders and when to ship.
                    </StyledP>
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    className="pb-5"
                    style={{ textAlign: "right" }}
                  >
                    <StaticImage
                      src="../assets/images/working.jpg"
                      alt="All Amazon Marketplaces In One Dashboard"
                      placeholder="tracedSVG"
                      layout="constrained"
                      aspectRatio={1 / 1}
                      height="400"

                      //                  width="400"
                    ></StaticImage>
                  </Col>
                  <div className="text-center">-------</div>

                  <Col
                    sm={12}
                    md={6}
                    className="pb-5"
                    style={{ textAlign: "right" }}
                  >
                    <StaticImage
                      src="../assets/images/problem.jpg"
                      alt="All Amazon Marketplaces In One Dashboard"
                      placeholder="tracedSVG"
                      layout="constrained"
                      aspectRatio={1 / 1}
                      height="400"

                      //                  width="400"
                    ></StaticImage>
                  </Col>

                  <Col sm={12} md={6} className="pb-5">
                    <StyledP>
                      Later next year, I suddenly found that some ASINs were
                      suppressed, I had multiple ASINs that went out of stock
                      for weeks, some for over a month, I felt like I am not in
                      control, I need to hire people to help! With hiring, and
                      that's a whole new subject to talk about, I knew it's not
                      the permanent solution and I was always thinking if I
                      really need to move forward, I need to automate the whole
                      thing...
                    </StyledP>
                  </Col>
                </Row>
              </Col>
            </Row>
            <DivSeperator></DivSeperator>

            <Row className="mx-auto">
              <Col>
                <StyledH className="text-center pb-5">
                  Nothing was out there
                </StyledH>
                <Row className="mx-auto" style={{ alignItems: "center" }}>
                  <Col sm={12} md={6} className="pb-5">
                    <StyledP>
                      I started to look for any apps that help me to do what was
                      in my mind, I tried at least 6 apps but found nothing does
                      what I want, that's besides all the issues and problems I
                      had to face with these apps.
                    </StyledP>
                    <StyledP>
                      It's now the time to move forward and do it yourself! Time
                      to hire some programmers...
                    </StyledP>
                  </Col>

                  <Col
                    sm={12}
                    md={6}
                    className="pb-5"
                    style={{ textAlign: "right" }}
                  >
                    <StaticImage
                      src="../assets/images/searching.jpg"
                      alt="All Amazon Marketplaces In One Dashboard"
                      placeholder="tracedSVG"
                      layout="constrained"
                      aspectRatio={1 / 1}
                      height="400"

                      //                  width="400"
                    ></StaticImage>
                  </Col>
                </Row>
              </Col>
            </Row>
            <DivSeperator></DivSeperator>

            <Row className="mx-auto">
              <Col>
                <StyledH className="text-center pb-5">The Idea</StyledH>
                <Row className="mx-auto" style={{ alignItems: "center" }}>
                  <Col
                    sm={12}
                    md={6}
                    className="pb-5"
                    style={{ textAlign: "right" }}
                  >
                    <StaticImage
                      src="../assets/images/idea-2.jpg"
                      alt="All Amazon Marketplaces In One Dashboard"
                      placeholder="tracedSVG"
                      layout="constrained"
                      aspectRatio={1 / 1}
                      height="400"

                      //                  width="400"
                    ></StaticImage>
                  </Col>
                  <Col sm={12} md={6} className="pb-5">
                    <StyledP>
                      That's when azszero idea started, and the name means
                      exactly what it's supposed to offer :
                    </StyledP>
                    <StyledP>
                      Amazon Seller with zero efforts, all routine things that
                      you have to do are automatically done by the app, I just
                      need to know what I need to know and it's the App's job to
                      save my time and tell me in a simple and modern way.
                    </StyledP>
                  </Col>
                </Row>
              </Col>
            </Row>
            <DivSeperator></DivSeperator>

            <Row className="mx-auto">
              <Col>
                <StyledH className="text-center pb-5">Now</StyledH>
                <Row className="mx-auto" style={{ alignItems: "center" }}>
                  <Col sm={12} md={6} className="pb-5">
                    <StyledP>
                      AZSzero App utilizes the most advanced technologies and
                      crunches data 24/7 just to deliver live reports and
                      notifications for all the sellers making them more
                      productive, cut their cost and expanding their business.
                    </StyledP>
                    <StyledP>
                      The Project started production on 2019, on 2021 we started
                      testing and accepting few customers for tests purposes
                      too. It took us about 2 years to almost accomplish my
                      original idea
                    </StyledP>
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    className="pb-5"
                    style={{ textAlign: "right" }}
                  >
                    <StaticImage
                      src="../assets/images/project.jpg"
                      alt="All Amazon Marketplaces In One Dashboard"
                      placeholder="tracedSVG"
                      layout="constrained"
                      aspectRatio={1 / 1}
                      height="400"

                      //                  width="400"
                    ></StaticImage>
                  </Col>
                </Row>
              </Col>
            </Row>
            <DivSeperator></DivSeperator>

            <Row className="mx-auto">
              <Col>
                <StyledH className="text-center pb-5">It's Not The End</StyledH>
                <Row className="mx-auto" style={{ alignItems: "center" }}>
                  <Col
                    sm={12}
                    md={6}
                    className="pb-5"
                    style={{ textAlign: "right" }}
                  >
                    <StaticImage
                      src="../assets/images/project2.jpg"
                      alt="All Amazon Marketplaces In One Dashboard"
                      placeholder="tracedSVG"
                      layout="constrained"
                      aspectRatio={1 / 1}
                      height="400"

                      //                  width="400"
                    ></StaticImage>
                  </Col>
                  <Col sm={12} md={6} className="pb-5">
                    <StyledP>
                      As this business evolves, the App will evolve too, there
                      will always be a new regulation, sales channel, or an
                      interesting report, we will always have everything new on
                      our roadmap.
                    </StyledP>
                    <StyledP>
                      We will always listen to our customers as many ideas come
                      from them, we will have a slot in the roadmap for that
                      too.
                    </StyledP>
                  </Col>
                </Row>
              </Col>
            </Row>
            <DivSeperator></DivSeperator>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

const StyledP = styled.p`
  font-size: clamp(1.2rem, 2.2vw, 1.5rem);
`

const StyledH = styled.h2`
  font-size: clamp(1.2rem, 2.2vw, 2rem);
`

export default AboutUs
